import { DatePipe, TitleCasePipe } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ApiContract } from '@api/api.contract'
import { ApiTimeRecords } from '@api/api.time-records'
import { EmployeeCompany } from '@interfaces/contract.interface'
import { pTableColumn, pTableTimeRecord } from '@interfaces/p-table.interface'
import { Person } from '@interfaces/personnel-management.interface'
import {
  NewTimeRecord,
  TimeRecord,
  UpdateTimeRecord,
} from '@interfaces/time-records.interface'
import { PersonnelManagementService } from '@services/personnel-management.service'
import { ToastService } from '@services/toast.service'
import { convertDatesToUTC } from '@utils/date-converter'
import { ConfirmationService } from 'primeng/api'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { FloatLabelModule } from 'primeng/floatlabel'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputTextModule } from 'primeng/inputtext'
import { ListboxModule } from 'primeng/listbox'
import { SidebarModule } from 'primeng/sidebar'
import { Table, TableModule } from 'primeng/table'
import { ApiService } from 'src/app/services/api.service'
import { TABLE_FILE_NAMES } from 'src/app/utils/p-table-names'
import { ModalComponent } from './components/modal.component'

// MARK: secondsToHMS
const secondsToHMS = (d: number) => {
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)
  const hh = h > 0 ? (`${h}`.length == 1 ? `0${h}` : `${h}`) : '00'
  const mm = m > 0 ? (`${m}`.length == 1 ? `0${m}` : `${m}`) : '00'
  const ss = s > 0 ? (`${s}`.length == 1 ? `0${s}` : `${s}`) : '00'
  return `${hh}:${mm}:${ss}`
}

@Component({
  selector: 'app-time-records',
  standalone: true,
  imports: [
    FormsModule,
    CalendarModule,
    ButtonModule,
    FloatLabelModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TableModule,
    TitleCasePipe,
    ConfirmDialogModule,
    SidebarModule,
    ListboxModule,
    ModalComponent,
    DatePipe,
  ],
  providers: [ConfirmationService],
  templateUrl: './time-records.component.html',
  styleUrl: './time-records.component.scss',
  host: {
    class: 'flexible',
  },
})
export class TimeRecordsComponent implements OnInit {
  // MARK: class members
  // table
  table_name = TABLE_FILE_NAMES['time_records']
  searchDate: Date | undefined
  columns: pTableColumn[] = [
    { field: 'cedula', header: 'identificación' },
    { field: 'usuario', header: 'usuario' },
    { field: 'empresa', header: 'empresa' },
    { field: 'created_at', header: 'fecha registro' },
    { field: 'entrada', header: 'hora entrada' },
    { field: 'salida', header: 'hora salida' },
    { field: 'cantidad', header: 'total horas' },
    { field: 'acciones', header: 'acciones' },
  ].map((column) => {
    return { field: column.field, header: column.header.toLowerCase() }
  })
  pTableData: pTableTimeRecord[] = []
  time_records: TimeRecord[] = []

  // sidebar
  sidebarTimeRecord = false
  time_record: TimeRecord | undefined
  new_time_record: NewTimeRecord = {
    entrada: '',
    salida: '',
    cantidad: '',
    contrato_id: -1,
  }
  today = new Date()
  inputDate: Date | undefined
  minDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  maxDate: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0,
  )

  employees: Person[] = []
  employeesFiltered: { value: number; label: string }[] = []
  employeeInput = ''
  employee_companies: EmployeeCompany[] = []
  companiesFiltered: EmployeeCompany[] = []
  companyInput = ''
  endDate = new Date()
  iniDate = new Date()

  // MARK: services setup
  @ViewChild(ModalComponent)
  modalComponent: ModalComponent | undefined

  constructor(
    private srvAPI: ApiService,
    private srvPersonnelManagement: PersonnelManagementService,
    private confirmationService: ConfirmationService,
    private srvToast: ToastService,
  ) {
    this.srvPersonnelManagement.employees$.subscribe((employees: any) => {
      this.employees = employees['active']
    })
  }

  // MARK: table
  ngOnInit() {
    this.initData()
  }

  initData() {
    this.srvAPI.get(ApiTimeRecords.obtenerTodas).subscribe((response: any) => {
      this.time_records = convertDatesToUTC(response['data'] as TimeRecord[], [
        'created_at',
      ])
      this.loadTable()
    })
  }

  loadTable() {
    this.pTableData = this.time_records.map((item) => {
      return {
        _id: item._id,
        contrato_id: item.contrato._id,
        cedula: item.contrato.cuenta.usuario,
        usuario: `${item.contrato.cuenta.persona.nombre} ${item.contrato.cuenta.persona.apellido}`,
        empresa: item.contrato.empresa.nombre,
        created_at: new Date(item.created_at),
        entrada: item.entrada,
        salida: item.salida,
        cantidad: item.cantidad,
      } as pTableTimeRecord
    })
  }

  filterByDate(value: Date, field: keyof pTableTimeRecord) {
    this.loadTable()
    this.pTableData = this.pTableData.filter((record) => {
      const recordDate = new Date(record[field])
      return recordDate.toDateString() === value.toDateString()
    })
  }

  exportToCSV(pTable: Table) {
    const last = this.columns.pop()
    this.pTableData.forEach((record) => {
      record.created_at = Intl.DateTimeFormat('es-EC', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(record.created_at as Date)
    })
    pTable.exportCSV()
    if (last) this.columns.push(last)
  }

  showDialog(persona: pTableTimeRecord) {
    if (!!!this.modalComponent) return

    const record = this.time_records.find(
      (record) => record._id === persona._id,
    )
    if (!!record) this.modalComponent.showDialog(record)
  }

  // MARK: sidebar
  cleanInputs() {
    this.endDate = new Date()
    this.iniDate = new Date()
    this.companyInput = ''
    this.employeeInput = ''
    this.time_record = undefined

    this.new_time_record = {
      entrada: '',
      salida: '',
      cantidad: '',
      contrato_id: -1,
    }
  }

  onEmployeeInput(event: Event) {
    const input = (event.target as HTMLInputElement).value
    if (input === '') this.employeesFiltered = []
    else
      this.employeesFiltered = this.employees
        .filter((employee) =>
          `${employee.nombre} ${employee.apellido}`
            .toLowerCase()
            .includes(input.trim().toLowerCase()),
        )
        .map((employee) => {
          return {
            value: employee._id,
            label: `${employee.nombre} ${employee.apellido}`,
          }
        })
  }
  changeEmployee(employee_id: number) {
    const employee = this.employees.find(
      (employee) => employee._id === employee_id,
    )
    this.employeeInput = `${employee?.nombre} ${employee?.apellido}` || ''
    this.employeesFiltered = []

    this.srvAPI
      .get(
        `${ApiContract.obtenerEmpresasContratoDeEmpleado}/${employee?.cuenta._id}`,
      )
      .subscribe((response: any) => {
        this.employee_companies = response['data'][
          'empresas'
        ] as EmployeeCompany[]
        this.companiesFiltered = this.employee_companies
        this.companyInput = ''
      })
  }

  onCompanyInput(event: Event) {
    const input = (event.target as HTMLInputElement).value
    this.companiesFiltered = this.employee_companies.filter((company) =>
      company.nombre.toLowerCase().includes(input.trim().toLowerCase()),
    )
  }
  changeCompany(company_id: number) {
    const company = this.employee_companies.find(
      (company) => company._id === company_id,
    )
    this.new_time_record.contrato_id = company?.contrato._id || -1
    this.companyInput = company?.nombre || ''
    this.companiesFiltered = []
  }

  openTimeRecord(p_table_record?: pTableTimeRecord) {
    this.srvPersonnelManagement.loadData()

    // inicializar fechas vacías
    this.inputDate = undefined
    this.endDate = new Date()
    this.iniDate = new Date()

    // inicializar fechas para actualizar un registro
    const record = this.time_records.find(
      (record) => record._id === p_table_record?._id,
    )
    this.time_record = record
    if (!!this.time_record) {
      this.inputDate = new Date(this.time_record.created_at)
      this.new_time_record.created_at = this.time_record.created_at
      this.new_time_record.contrato_id = this.time_record.contrato._id

      if (!!this.time_record.salida || !!this.time_record.entrada) {
        this.endDate = new Date(this.time_record.created_at)
        const endTime = !!this.time_record.salida
          ? this.time_record.salida.split(':')
          : this.time_record.entrada.split(':')
        this.endDate.setHours(+endTime[0])
        this.endDate.setMinutes(+endTime[1])
        this.endDate.setSeconds(+endTime[2])
      }

      if (!!this.time_record.entrada) {
        this.iniDate = new Date(this.time_record.created_at)
        const iniTime = this.time_record.entrada.split(':')
        this.iniDate.setHours(+iniTime[0])
        this.iniDate.setMinutes(+iniTime[1])
        this.iniDate.setSeconds(+iniTime[2])
      }
    }

    this.updateQuantity()
    this.sidebarTimeRecord = true
  }

  updateQuantity() {
    if (!!!this.iniDate || !!!this.endDate) return

    // copias de las fechas originales
    const end = new Date(this.endDate)
    const ini = new Date(this.iniDate)

    /* 
      crea un ciclo temporal bien mamalon
      esto permite registrar una cantidad de 24h
      independientemente del horario, ya sea:
      matutino, vespertino, nocturno o madrugada
    */

    // if (fecha de salida es mayor o igual a entrada) then salida = fecha original
    if (end.getTime() - ini.getTime() >= 0) end.setDate(this.endDate.getDate())
    // else (salida es menor a entrada) then salida = salida + 1 día
    else end.setDate(this.endDate.getDate() + 1)

    // calcula el tiempo en segundos y usa secondsToHMS()
    // para obtener el resultado en string 'hh:mm:ss'
    const timeInSeconds = (end.getTime() - ini.getTime()) / 1000
    this.new_time_record.cantidad = secondsToHMS(timeInSeconds)
  }

  alertCreatedAt() {
    this.srvToast.info({
      title: 'Periodo vigente',
      message: 'Solo fechas en el mes actual',
    })
  }

  saveTimeRecord() {
    this.new_time_record.entrada = Intl.DateTimeFormat('es-EC', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).format(this.iniDate)
    this.new_time_record.salida = Intl.DateTimeFormat('es-EC', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).format(this.endDate)

    if (!!this.time_record) {
      const body: UpdateTimeRecord = {
        entrada: this.new_time_record.entrada,
        salida: this.new_time_record.salida,
        cantidad: this.new_time_record.cantidad,
        created_at: this.new_time_record.created_at,
      }

      this.srvAPI
        .patch(
          `${ApiTimeRecords.editarAsistencia}/${this.time_record._id}`,
          body,
        )
        .subscribe({
          next: (response: any) => {
            this.srvToast.success({
              title: response.status,
              message: 'Se actualizó el registro',
            })
            this.initData()
          },
          error: (error) => {
            this.srvToast.error({
              title: `${error.error.statusCode} ${error.error.error}`,
              message: error.error.message,
            })
          },
        })

      return
    }

    this.srvAPI
      .post(ApiTimeRecords.crearAsistencia, this.new_time_record)
      .subscribe({
        next: (response: any) => {
          this.srvToast.success({
            title: response.status,
            message: 'Se creó el registro',
          })
          this.initData()
        },
        error: (error) => {
          this.srvToast.error({
            title: `${error.error.statusCode} ${error.error.error}`,
            message: error.error.message,
          })
        },
      })
  }

  deleteTimeRecord(event: Event, time_record: TimeRecord) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'El registro se eliminará permanentemente',
      header: 'Eliminar el registro',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Eliminar',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'bg-red-color border-none rounded-full',
      rejectButtonStyleClass: 'p-button-text text-red-color rounded-full',
      accept: () => {
        this.srvAPI
          .delete(`${ApiTimeRecords.eliminarAsistencia}/${time_record._id}`)
          .subscribe({
            next: (response: any) => {
              this.srvToast.success({
                title: response.status,
                message: 'Se eliminó el registro',
              })
              this.initData()
            },
            error: (error) => {
              this.srvToast.error({
                title: `${error.error.statusCode} ${error.error.error}`,
                message: error.error.message,
              })
            },
          })
      },
    })
  }
}
