import { Injectable } from '@angular/core'
import { ApiAccounting } from '@api/api.accounting'
import { Expense, Income } from '@interfaces/accounting.interface'
import { convertDatesToUTC } from '@utils/date-converter'
import { BehaviorSubject } from 'rxjs'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root',
})
export class AccountingService {
  private expense = new BehaviorSubject<Expense[]>([])
  private income = new BehaviorSubject<Income[]>([])

  get expense$() {
    return this.expense.asObservable()
  }
  get income$() {
    return this.income.asObservable()
  }

  constructor(private srvAPI: ApiService) {}

  loadData() {
    this.loadExpense()
    this.loadIncome()
  }

  loadExpense() {
    this.srvAPI
      .get(`${ApiAccounting.obtenerIngresoEgresoTodos}/1`)
      .subscribe((response: any) => {
        const expense = convertDatesToUTC(response['data'] as Expense[], [
          'created_at',
          'updated_at',
        ])
        this.expense.next(expense)
      })
  }

  loadIncome() {
    this.srvAPI
      .get(`${ApiAccounting.obtenerIngresoEgresoTodos}/0`)
      .subscribe((response: any) => {
        const income = convertDatesToUTC(response['data'] as Income[], [
          'created_at',
          'updated_at',
        ])
        this.income.next(income)
      })
  }
}
