import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  TitleCasePipe,
} from '@angular/common'
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { ApiAccounting } from '@api/api.accounting'
import { ApiContract } from '@api/api.contract'
import {
  Contract,
  Expense,
  ResolveExpense,
} from '@interfaces/accounting.interface'
import { EmployeeCompany } from '@interfaces/contract.interface'
import { Person } from '@interfaces/personnel-management.interface'
import { ReportPayment, ReportPerUser } from '@interfaces/report.interface'
import { ApiService } from '@services/api.service'
import { ToastService } from '@services/toast.service'
import { AvatarModule } from 'primeng/avatar'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { ChipModule } from 'primeng/chip'
import { DialogModule } from 'primeng/dialog'
import { FloatLabelModule } from 'primeng/floatlabel'
import { InputTextModule } from 'primeng/inputtext'
import { TableModule } from 'primeng/table'
import { TooltipModule } from 'primeng/tooltip'
import { PadNumberPipe } from 'src/app/utils/pad-number.pipe'

@Component({
  selector: 'app-info-panel',
  standalone: true,
  imports: [
    FormsModule,
    FloatLabelModule,
    InputTextModule,
    CalendarModule,
    ButtonModule,
    TooltipModule,
    TableModule,
    DialogModule,
    AvatarModule,
    ChipModule,
    TitleCasePipe,
    CurrencyPipe,
    DecimalPipe,
    DatePipe,
    PadNumberPipe,
  ],
  templateUrl: './info-panel.component.html',
  styleUrl: './info-panel.component.scss',
  host: {
    class: 'flexible',
  },
})
export class InfoPanelComponent implements OnChanges {
  @Input() employee: Person | undefined
  @Input() company: EmployeeCompany | undefined

  isPaymentDisabled = true
  today = new Date()
  searchDate: Date | undefined
  report: ReportPerUser = {
    asistencias: [],
    ingresos: [],
    egresos: [],
    totalHoras: 0,
    resultado_ingresos: 0,
    resultado_egresos: 0,
    subTotal: 0,
    total: 0,
  }

  income_columns = ['concepto', 'monto', 'fecha solicitud', 'acciones'].map(
    (column) => column.toLowerCase(),
  )
  expense_columns = [...this.income_columns]

  expenseToDisplay: Expense | undefined
  displayVisible = false

  constructor(
    private route: ActivatedRoute,
    private srvAPI: ApiService,
    private srvToast: ToastService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const employee = changes['employee']?.currentValue as Person
    if (!!employee) this.employee = employee

    const company = changes['company']?.currentValue as EmployeeCompany
    if (!!company) this.company = company

    if (!!employee && !!company) {
      this.searchDate = new Date()
      const month = +this.route.snapshot.queryParams['month']
      // month in 0-11 index then -1
      if (!!month) this.searchDate.setMonth(month - 1)
      this.loadData()
    }
  }

  loadData() {
    this.isPaymentDisabled = true
    if (!!!this.searchDate) return
    this.searchDate.setMonth(this.searchDate.getMonth() + 1)
    this.searchDate.setDate(-1)

    this.srvAPI
      .get(
        `${ApiContract.obtenerReporteDePago}/${this.company?._id}/${
          this.employee?.cuenta._id
        }/${this.searchDate.toISOString().split('T')[0]}`,
      )
      .subscribe((response: any) => {
        this.report = response['data'] as ReportPerUser

        // payment controls
        this.isPaymentDisabled =
          // if total is lesser than 0
          this.report.total <= 0 ||
          // if it's already paid
          !!this.report.pago ||
          // if expense aren't paid yet
          !this.areExpensesResolved ||
          // if there's not searchDate (no month selected)
          !!!this.searchDate ||
          // if searchDate >= today then disable payment
          (this.searchDate &&
            this.searchDate.getFullYear() >= this.today.getFullYear() &&
            this.searchDate.getMonth() >= this.today.getMonth())
      })
  }

  get areExpensesResolved() {
    // if expense forma = fijo (1) and estado = resolver (0)
    return (
      this.report.egresos.findIndex(
        (ex) => ex.forma === 1 && ex.estado === 0,
      ) === -1
    )
  }

  viewExpense(expense: Expense) {
    this.expenseToDisplay = this.report.egresos.find(
      (item) => item._id === expense._id,
    ) as Expense | undefined
    if (!!!this.expenseToDisplay) return

    this.expenseToDisplay.contrato = {
      cuenta: {
        persona: {
          nombre: this.employee?.nombre,
          apellido: this.employee?.apellido,
          cedula: this.employee?.cuenta.usuario,
        },
      },
      empresa: { nombre: this.company?.nombre },
    } as Contract
    this.expenseToDisplay.pago = expense.monto
    this.expenseToDisplay.updated_at = new Date().toISOString().split('T')[0]
    this.displayVisible = true
  }

  resolveExpense(estado: 1 | 2, expense: Expense) {
    const resolve = {
      estado,
      respuesta: expense.respuesta,
    } as ResolveExpense

    this.srvAPI
      .patch(`${ApiAccounting.resolverEgreso}/${expense._id}`, resolve)
      .subscribe({
        next: (response: any) => {
          this.srvToast.info({
            title: response.status,
            message: 'Egreso resuelto',
          })
          this.loadData()
          this.displayVisible = false
        },
        error: (error) => {
          this.srvToast.error({
            title: `${error.error.statusCode} ${error.error.error}`,
            message: error.error.message,
          })
        },
      })
  }

  createPayment() {
    // payment controls
    if (this.isPaymentDisabled || !!!this.searchDate) return

    const payment: ReportPayment = {
      fecha_pago: new Date().toISOString(),
      fecha_periodo: this.searchDate.toISOString(),
      total: this.report.total,
      sub_total: this.report.subTotal,
      ingresos: this.report.resultado_ingresos,
      egresos: this.report.resultado_egresos,
      horas_laboradas: this.report.totalHoras,
      descripcion: '',
      estado: 0,
    }

    this.srvAPI
      .post(
        `${ApiContract.registrarPago}/${this.company?.contrato._id}`,
        payment,
      )
      .subscribe({
        next: (response: any) => {
          this.srvToast.success({
            title: response.status,
            message: 'Pago registrado',
          })
          this.loadData()
        },
        error: (error) => {
          this.srvToast.error({
            title: `Error ${error.status}`,
            message: error.error.message,
          })
        },
      })
  }
}
