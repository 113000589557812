<section
  class="absolute flexible w-full overflow-y-scroll px-3 pb-5 max-h-[80vh]"
>
  <div class="flex items-center justify-between">
    <div class="flex items-center justify-between">
      <img
        [src]="company?.url_logo"
        alt="logo empresa"
        class="rounded-full shadow-sm object-contain aspect-square w-[60px] h-[60px]"
      />
      <p class="mx-5 text-2xl">{{ company?.nombre }}</p>
    </div>
    <div class="flex items-center justify-center">
      <p class="mx-5">
        <span class="pi pi-exclamation-triangle text-[goldenrod]"></span>
        <span class="mx-1">Pendiente de pago</span>
      </p>
      <button
        class="flex items-center justify-center px-5 py-2 rounded-full hover:bg-red-50"
        (click)="deleteEmployeeContract($event, company?.contrato?._id)"
      >
        <div
          class="flex items-center justify-center p-1 rounded-full bg-red-color drop-shadow-sm ring-5"
        >
          <span class="text-lg text-white pi pi-minus"></span>
        </div>
        <p class="mx-1 hover:bg-transparent">Remover asignación</p>
      </button>
    </div>
  </div>
  <div class="flex items-center">
    <p-button
      styleClass="text-nowrap py-1 my-3"
      type="button"
      label="Ver reporte"
      icon="pi pi-arrow-right"
      severity="success"
      [rounded]="true"
      (onClick)="goToReportPerUser()"
    />
  </div>
  <div class="flex items-center justify-between w-1/2 py-5 text-lg">
    <p class="mx-5">
      <span class="font-bold">Supervisor:</span>
      <span class="mx-2">{{ company?.supervisor || 'N/A' }}</span>
    </p>
    <p class="mx-5">
      <span class="font-bold">Departamento:</span>
      <span class="mx-2">{{
        company?.contrato?.departamento?.nombre || 'N/A'
      }}</span>
    </p>
  </div>
  <div class="flex items-center justify-center w-1/3 gap-x-5">
    <div class="flex flex-col w-full p-3 rounded-md shadow-sm">
      <p class="flex items-center justify-between w-full text-lg font-bold">
        <span>$ {{ company?.contrato?.valor }}</span>
        <span class="text-lg pi pi-money-bill"></span>
      </p>
      <p>Valor hora</p>
    </div>
    <div class="flex flex-col w-full p-3 rounded-md shadow-sm">
      <p class="flex items-center justify-between w-full text-lg font-bold">
        <span>{{ company?.contrato?.descanso }} min</span>
        <span class="text-lg pi pi-heart"></span>
      </p>
      <p>descanso</p>
    </div>
  </div>
  <div>
    <div class="flex items-center justify-between w-full">
      <p>Ingresos recurrentes</p>
      <p-button
        styleClass="py-2 px-5"
        icon="pi pi-angle-double-up"
        label="Crear"
        severity="danger"
        [rounded]="true"
        [text]="true"
        (onClick)="openCreateRecurringItem(company?.contrato?._id || -1)"
      />
    </div>
    <p-table
      #pTable
      class="max-h-[70vh] pb-3"
      styleClass="ring-[1px] ring-[gainsboro] rounded-[10px] text-nowrap my-2"
      tableStyleClass="min-w-[10px]"
      [value]="income"
      [rows]="10"
    >
      <ng-template pTemplate="header">
        <tr>
          @for (column of columns; track $index) { @if ($index !==
          columns.length - 1) {
          <th [pSortableColumn]="column" class="w-[25%]">
            {{ column | titlecase }}
            <p-sortIcon [field]="column" />
          </th>
          } @else {
          <th class="w-auto">
            {{ column | titlecase }}
          </th>
          } }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-income>
        <tr>
          <td
            class="truncate max-w-[300px]"
            tooltipStyleClass="min-w-[300px] custom-tooltip"
            tooltipPosition="top"
            [pTooltip]="
              income.concepto.length > 22 ? income.concepto : undefined
            "
          >
            {{ income.concepto }}
          </td>
          <td>{{ income.monto | currency }}</td>
          <td>{{ income.fecha_inicio | date : 'dd-MM-yyyy' }}</td>
          <td>{{ income.fecha_fin | date : 'dd-MM-yyyy' }}</td>
          <td class="custom-table-actions">
            <div class="flex items-center gap-x-1">
              <p-button
                styleClass="bg-[gainsboro] py-2 px-5 border-none rounded-full"
                icon="text-red-color pi pi-trash"
                (onClick)="deleteRecurringItem($event, income)"
              />
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div>
    <div class="flex items-center justify-between w-full">
      <p>Egresos recurrentes</p>
      <p-button
        styleClass="py-2 px-5"
        icon="pi pi-angle-double-down"
        label="Crear"
        severity="danger"
        [rounded]="true"
        [text]="true"
        (onClick)="openCreateRecurringItem(company?.contrato?._id || -1, false)"
      />
    </div>
    <p-table
      #pTable
      class="max-h-[70vh] pb-3"
      styleClass="ring-[1px] ring-[gainsboro] rounded-[10px] text-nowrap my-2"
      tableStyleClass="min-w-[10px]"
      [value]="expense"
      [rows]="10"
    >
      <ng-template pTemplate="header">
        <tr>
          @for (column of columns; track $index) { @if ($index !==
          columns.length - 1) {
          <th [pSortableColumn]="column" class="w-[25%]">
            {{ column | titlecase }}
            <p-sortIcon [field]="column" />
          </th>
          } @else {
          <th class="w-auto">
            {{ column | titlecase }}
          </th>
          } }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-expense>
        <tr>
          <td
            class="truncate max-w-[300px]"
            tooltipStyleClass="min-w-[300px] custom-tooltip"
            tooltipPosition="top"
            [pTooltip]="
              expense.concepto.length > 22 ? expense.concepto : undefined
            "
          >
            {{ expense.concepto }}
          </td>
          <td>{{ expense.monto | currency }}</td>
          <td>{{ expense.fecha_inicio | date : 'dd-MM-yyyy' }}</td>
          <td>{{ expense.fecha_fin | date : 'dd-MM-yyyy' }}</td>
          <td class="custom-table-actions">
            <div class="flex items-center gap-x-1">
              <p-button
                styleClass="bg-[gainsboro] py-2 px-5 border-none rounded-full"
                icon="text-red-color pi pi-trash"
                (onClick)="deleteRecurringItem($event, expense, false)"
              />
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</section>

<!-- MARK: MODALS -->
<div class="flex items-center justify-center">
  <p-confirmDialog />
</div>

<p-sidebar
  styleClass="w-[350px] flex flex-col flex-1"
  [(visible)]="sidebarVisible"
  position="right"
>
  <ng-template pTemplate="header">
    <h3>Nuevo {{ flagIsIncome ? 'ingreso' : 'egreso' }}</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form
      class="flex flex-col justify-around flex-1 px-5 pb-10"
      (submit)="createRecurringItem()"
    >
      <div class="flex flex-col items-center flex-1 w-full gap-y-10">
        <div
          class="flex items-center w-full rounded-full py-1 px-3 text-lg bg-[gainsboro]"
        >
          <span class="pi pi-calendar"></span>
          <p class="w-full mx-4 text-left">desde {{ today }}</p>
        </div>
        <p-floatLabel class="w-full">
          <p-calendar
            class="w-full"
            styleClass="w-full"
            [showButtonBar]="true"
            [(ngModel)]="endDate"
            [showIcon]="true"
            [minDate]="iniDate"
            dateFormat="'hasta' MM 'de' yy"
            view="month"
            name="endDate"
            inputId="endDate"
          />
          <label for="created_at">Fecha de fin</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            [(ngModel)]="new_recurring_item['concepto']"
            id="concept"
            type="text"
            name="concept"
          />
          <label for="concept">Concepto o detalle</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <p-inputNumber
            class="w-full"
            styleClass="w-full"
            [(ngModel)]="new_recurring_item['monto']"
            mode="currency"
            currency="USD"
            [min]="0"
            [maxlength]="7"
            name="amount"
            inputId="amount"
          />
          <label for="amount">Monto</label>
        </p-floatLabel>
      </div>

      <div class="flex items-center justify-center w-full h-[7vh]">
        <p-button
          class="w-[70%]"
          styleClass="w-full"
          label="Registrar {{ flagIsIncome ? 'ingreso' : 'egreso' }}"
          [severity]="flagIsIncome ? 'success' : 'danger'"
          [rounded]="true"
          type="submit"
        />
      </div>
    </form>
  </ng-template>
</p-sidebar>
