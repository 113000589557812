<section class="flex flex-col flex-1">
  <div
    class="flex flex-row-reverse items-center justify-between w-full px-1 py-3 mb-5 lg:px-5 sm:justify-center min-[500px]:flex-row"
  >
    <div class="min-[1200px]:w-[50%] flex items-center">
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Volver"
        icon="pi pi-arrow-left"
        severity="success"
        [text]="true"
        (onClick)="goBack()"
      />
    </div>

    <div
      class="w-full min-[1200px]:w-[50%] mx-2 flex justify-center lg:justify-end items-center"
    >
      <p-iconField class="w-full lg:w-[70%]" iconPosition="left">
        <p-inputIcon styleClass="pi pi-search" />
        <input
          class="w-full border-none rounded-full bg-grey-color"
          pInputText
          #pSearchInput
          type="text"
          placeholder="Identificación o nombre"
          (input)="filterCompanies(pSearchInput.value)"
        />
      </p-iconField>
    </div>
  </div>

  <div class="max-w-[100vw] p-5 pt-0 mb-1 flex flex-1">
    <div
      class="relative w-[30%] shadow-md rounded-[10px] p-5 flex flex-col overflow-hidden"
    >
      <div
        class="flex flex-col items-center justify-center w-full py-5 mb-5 bg-aqua-color rounded-[10px]"
      >
        <img
          [src]="employee?.url_image"
          alt=""
          class="object-contain aspect-square w-[150px] h-[150px]"
        />
        <p class="mt-5 text-lg">
          {{ employee?.nombre + ' ' + employee?.apellido }}
        </p>
        <p>{{ employee?.cuenta?.usuario }}</p>
      </div>
      <div class="relative overflow-y-scroll flexible">
        <div class="absolute inset-x-0 w-full pt-5 pr-2 pb-28 flexible">
          <p class="flex justify-between mt-5 text-lg">
            <span><span class="mx-2 pi pi-envelope"></span> Correo:</span>
            <span>{{ employee?.correo }}</span>
          </p>
          <p class="flex justify-between mt-5 text-lg">
            <span><span class="mx-2 pi pi-mobile"></span> Teléfono:</span>
            <span>{{ !!employee?.telefono ? employee?.telefono : 'N/A' }}</span>
          </p>
          <p class="flex justify-between mt-5 text-lg">
            <span
              ><span class="mx-2 pi pi-id-card"></span> Especializaciones:</span
            >
          </p>
          <div class="flex flex-wrap items-center gap-2 px-2 py-3">
            @if (!!employee && !!employee.personaEspecialidades) {
            <!--  -->
            @switch (employee.personaEspecialidades.length) {
            <!--  -->
            @case (1) {
            <p-chip styleClass="py-2 bg-[#ECF6FE]">
              {{ employee.personaEspecialidades[0].especialidad.nombre }}
            </p-chip>
            }
            <!--  -->
            @case(2) {
            <p-chip styleClass="py-2 bg-[#ECF6FE]">
              {{ employee.personaEspecialidades[0].especialidad.nombre }}
            </p-chip>
            <p-chip styleClass="py-2 bg-[#ECF6FE]">
              {{ employee.personaEspecialidades[1].especialidad.nombre }}
            </p-chip>
            }
            <!--  -->
            @default {

            <!--  -->
            @if (employee.personaEspecialidades.length > 2) {
            <button type="button" (click)="toggleSpecialties()">
              <p-chip styleClass="py-2 bg-[#FFFCEB] rounded-full">
                {{
                  isSpecialtiesOpen
                    ? 'menos'
                    : '+' + (employee.personaEspecialidades.length - 2)
                }}
              </p-chip>
            </button>
            <p-chip styleClass="py-2 bg-[#ECF6FE]">
              {{ employee.personaEspecialidades[0].especialidad.nombre }}
            </p-chip>
            <p-chip styleClass="py-2 bg-[#ECF6FE]">
              {{ employee.personaEspecialidades[1].especialidad.nombre }}
            </p-chip>
            <!--  -->
            @if (isSpecialtiesOpen) {
            <!--  -->
            @for (specialty of employee.personaEspecialidades.slice(2); track
            $index) {
            <p-chip styleClass="py-2 bg-[#ECF6FE]">
              {{ specialty.especialidad.nombre }}
            </p-chip>
            } } } } } }
          </div>
          <div>
            <label for="name">Pasaporte</label>
            <div
              class="flex justify-center p-2 bg-blue-100 border-4 border-gray-500 border-dashed rounded-xl"
            >
              <img
                [src]="employee?.url_pasaporte"
                alt=""
                class="object-contain aspect-square h-[200px] w-full"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="absolute inset-x-0 bottom-0 flex items-center justify-center w-full pt-5 pb-10 bg-white"
      >
        <p-button
          styleClass="scale-125"
          label="Editar empleado"
          icon="pi pi-pencil"
          severity="danger"
          [text]="true"
          (onClick)="openEditEmployee()"
        />
      </div>
    </div>

    <div class="max-w-[100vw] p-5 mb-1 flexible relative">
      @if (!!companyToDisplay) {
      <router-outlet name="employee-contract" class="w-full"></router-outlet>
      } @else {
      <div class="flex justify-end w-full pb-5">
        <p-button
          styleClass="text-nowrap py-2"
          type="button"
          label="Asignar empresa"
          icon="pi pi-building"
          severity="danger"
          [rounded]="true"
          (onClick)="openEmployeeEnrollment()"
        />
      </div>

      <section class="max-h-[75vh] relative w-full flexible">
        @if (!!employee_companies_filtered && employee_companies_filtered.length
        > 0) {
        <div
          class="max-h-[72vh] absolute grid w-full grid-cols-1 gap-10 px-5 pb-7 pt-0 overflow-y-auto sm:grid-cols-2 2xl:grid-cols-3"
        >
          @for (company of employee_companies_filtered; track $index) {
          <div
            class="relative flex flex-col content-center justify-start px-5 py-5 rounded-[6px] shadow-md border-solid border-[1px]"
          >
            <div class="z-10 w-full">
              <p-inputSwitch
                [ngModel]="company['contrato'].estado ? true : false"
                (onChange)="
                  handleDesableEmployee($event, company['contrato']['_id'])
                "
                class="float-left"
              />
              <button
                class="text-lg text-white pi pi-minus bg-red-color hover:ring-8 hover:ring-red-50 p-1 rounded-[50%] float-right"
                (click)="
                  deleteEmployeeContract($event, company['contrato']['_id'])
                "
              ></button>
            </div>
            <button (click)="goToEmployeeContract(company)">
              <div class="flex justify-center">
                <img
                  [src]="company.url_logo"
                  alt="logo empresa"
                  class="object-contain aspect-square w-[100px] h-[100px]"
                />
              </div>
              <p class="my-5 text-lg">{{ company.nombre }}</p>
              <p class="flex justify-between w-full text-base">
                <span>Supervisor:</span>
                <span>{{
                  !!company.supervisor ? company.supervisor : 'N/A'
                }}</span>
              </p>
              <p class="flex justify-between w-full text-base">
                <span>Departamento:</span>
                <span>{{
                  !!company.contrato.departamento
                    ? company.contrato.departamento.nombre
                    : 'N/A'
                }}</span>
              </p>
            </button>
          </div>
          }
        </div>
        } @else if (isLoadingCompanies) {
        <p-progressSpinner
          class="w-full py-10 text-center"
          styleClass="w-[4rem] h-[4rem] custom-spinner"
          strokeWidth="4"
          animationDuration="2s"
          fill="transparent"
        />
        } @else {
        <div
          class="flex flex-col justify-between w-full py-10 text-xl text-center"
        >
          <i class="text-3xl text-[--red-color] pi pi-exclamation-circle"></i>
          <p class="my-3">No tiene ninguna empresa asignada</p>
        </div>
        }
      </section>
      }
    </div>
  </div>
</section>

<!-- MARK: MODALS 🔥 -->
<div class="flex items-center justify-center">
  <p-confirmDialog />
</div>

<p-sidebar
  styleClass="w-[350px] flex flex-col flex-1"
  [(visible)]="sidebarEmployee"
  position="right"
>
  <ng-template pTemplate="header">
    <h3>Editar Persona</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form class="flex flex-col justify-around flex-1 px-5 pb-10">
      <div class="flex flex-col flex-1 w-full gap-y-10">
        <div class="flex items-center justify-center w-full py-5">
          <input
            class="hidden w-full"
            #fileInputImage
            type="file"
            (change)="uploadFileImage($event)"
            accept="image/*"
          />

          @if (!!previewImage || !!employee?.url_image) {
          <img
            role="button"
            class="object-contain aspect-square w-[150px] h-[150px]"
            alt="perfil"
            [src]="previewImage ?? employee?.url_image"
            (click)="fileInputImage.click()"
          />
          } @else {
          <div
            class="flex items-center justify-center w-[150px] h-[150px]"
            (click)="fileInputImage.click()"
          >
            <div
              role="button"
              class="relative flex items-center justify-center rounded-full w-[100px] h-[100px] bg-[#F5F5F5]"
            >
              <span class="scale-[200%] pi pi-user"></span>
              <div
                class="absolute bottom-0 right-0 rounded-full flex justify-center items-center bg-[#FEF6F7] w-[40px] h-[40px] drop-shadow-sm ring-5 ring-white"
              >
                <span class="scale-150 pi pi-camera"></span>
              </div>
            </div>
          </div>
          }
        </div>

        <div>
          <label for="name">Pasaporte</label>
          <div
            class="flex justify-center p-2 bg-blue-100 border-4 border-gray-500 border-dashed rounded-xl"
          >
            <img
              role="button"
              [src]="previewPasaporte ?? employee?.url_pasaporte"
              alt=""
              class="object-contain aspect-square h-[200px] w-full"
              (click)="fileInputPasaporte.click()"
            />
            <input
              class="w-full"
              #fileInputPasaporte
              type="file"
              (change)="uploadFilePasaporte($event)"
              style="display: none"
              accept="image/*"
            />
          </div>
        </div>
        <p-floatLabel>
          <input
            class="w-full"
            pInputText
            id="cedula"
            type="text"
            name="cedula"
            [value]="employee?.cuenta?.usuario"
            (input)="onChangeCedula($event)"
          />
          <label for="cedula">Identificación</label>
        </p-floatLabel>
        <p-floatLabel>
          <input
            class="w-full"
            pInputText
            id="nombre"
            type="text"
            name="nombre"
            [value]="employee?.nombre"
            (input)="onChangeNombres($event)"
          />
          <label for="nombre">Nombres</label>
        </p-floatLabel>
        <p-floatLabel>
          <input
            class="w-full"
            pInputText
            id="apellidos"
            type="text"
            name="apellidos"
            [value]="employee?.apellido"
            (input)="onChangeApellidos($event)"
          />
          <label for="apellidos">Apellidos</label>
        </p-floatLabel>

        <p-floatLabel>
          <input
            class="w-full"
            pInputText
            id="email"
            type="text"
            name="email"
            [value]="employee?.correo"
            (input)="onChangeCorreo($event)"
          />
          <label for="email">Correo electrónico</label>
        </p-floatLabel>
        <p-floatLabel>
          <input
            class="w-full"
            pInputText
            id="telefono"
            type="text"
            name="telefono"
            [value]="employee?.telefono"
            (input)="onChangeTelefono($event)"
          />
          <label for="telefono">Teléfono</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            placeholder="Agregar presionando Enter"
            id="specialty"
            type="text"
            name="specialty"
            (keydown.enter)="onEnterAddSpecialty($event)"
          />
          <label for="specialty">Especialidad</label>
        </p-floatLabel>
      </div>
      @if(especialidades.length > 0) {
      <div class="flex flex-wrap items-center justify-end w-full gap-2 my-5">
        @for (specialty of especialidades; track $index) {
        <button type="button" (click)="removeSpecialty(specialty)">
          <p-chip styleClass="py-2 bg-[#ECF6FE]">
            {{ specialty }}
          </p-chip>
        </button>
        }
      </div>
      }
      <div class="flex items-center justify-center w-full mt-5 h-[7vh]">
        <p-button
          class="w-[70%]"
          styleClass="w-full"
          type="submit"
          label="Guardar cambios"
          severity="danger"
          [rounded]="true"
          [loading]="isCreateInProgress"
          (onClick)="saveChanges()"
        />
      </div>
    </form>
  </ng-template>
</p-sidebar>

<p-sidebar
  styleClass="w-[350px] flex flex-col flex-1"
  [(visible)]="sidebarEnrollment"
  position="right"
>
  <ng-template pTemplate="header">
    <h3>Asignar empresa a empleado</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form
      class="flex flex-col justify-around flex-1 px-5 pb-10"
      (submit)="createContract()"
    >
      <div class="flex flex-col items-center flex-1 w-full pt-5 gap-y-10">
        <p-dropdown
          class="w-full"
          styleClass="w-full"
          [options]="companies"
          optionLabel="nombre"
          id="company"
          name="company"
          placeholder="Empresa"
          (onChange)="changeCompanyAndLoadDepartments($event.value['_id'])"
        />
        <p-dropdown
          class="w-full"
          styleClass="w-full"
          [options]="departments"
          optionLabel="nombre"
          id="department"
          name="department"
          placeholder="Departamento"
          [disabled]="departments.length <= 0"
          (onChange)="new_contract['departamento_id'] = $event.value['_id']"
        />
        <p-floatLabel class="w-full">
          <p-inputNumber
            class="w-full"
            styleClass="w-full"
            [(ngModel)]="new_contract['valor']"
            mode="currency"
            currency="USD"
            [min]="0"
            [maxlength]="7"
            name="value_per_hour"
            inputId="value_per_hour"
          />
          <label for="value_per_hour">Valor / hora</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <p-inputNumber
            class="w-full"
            styleClass="w-full"
            [(ngModel)]="new_contract['descanso']"
            suffix=" min"
            [min]="0"
            [maxlength]="3"
            name="breaks"
            inputId="breaks"
          />
          <label for="breaks">Descanso</label>
        </p-floatLabel>
      </div>

      <div class="flex items-center justify-center w-full h-[7vh]">
        <p-button
          class="w-[70%]"
          styleClass="w-full"
          label="Asignar empresa"
          severity="danger"
          [rounded]="true"
          type="submit"
        />
      </div>
    </form>
  </ng-template>
</p-sidebar>
