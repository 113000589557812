export function convertDatesToUTC<T extends { [key: string]: any }>(
  items: T[],
  properties: (keyof T)[],
): T[] {
  return items.map((item) => {
    const formattedItem: any = { ...item }

    for (const prop of properties) {
      if (formattedItem[prop]) {
        try {
          const date = new Date(formattedItem[prop])
          if (!isNaN(date.getTime())) {
            // conversion
            formattedItem[prop] = `${date.toISOString().split('.')[0]}-05:00`
          }
        } catch (error) {
          console.warn(error)
          formattedItem[prop] = null
        }
      }
    }
    return formattedItem
  })
}
